import logo from "./logo.svg";
import "./App.css";
import "./App.scss";
import { Container, Button, Image } from "react-bootstrap";
import liIcon from "./linkedin.svg";
import fbIcon from "./facebook.svg";

function App() {
  return (
    <Container
      className="px-5 hyrebook-maintenance"
      style={styles.parentContainer}
      fluid
    >
      <div>
        <img src={logo} alt="logo" className="py-3" />
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center mt-4">
        <h3 style={styles.title}>The Road to Success is Under Construction.</h3>
        <h3 style={styles.title}>We will be Ready Very Soon...</h3>
        <div className="d-flex justify-content-center my-4">
          <Button
            type="link"
            href="https://www.linkedin.com/company/hyrebook"
            className="hyrebook-button m-2 nunito-500 d-flex align-items-center"
            style={styles.linkedInButton}
          >
            LinkedIn <Image src={liIcon} className="ms-1" />
          </Button>
          <Button
            type="link"
            href="https://www.facebook.com/HyreBook/"
            className="hyrebook-button m-2 nunito-500 d-flex align-items-center"
            style={styles.facebookButton}
          >
            Facebook <Image src={fbIcon} className="ms-1"/>
          </Button>
        </div>
      </div>
    </Container>
  );
}

const styles = {
  parentContainer: {
    height: "100vh",
  },
            title: {
              letterSpacing: 4,
            fontSize: 36,
            fontWeight: 700,
  },
            linkedInButton: {
              backgroundColor: "#0E76A8",
  },
            facebookButton: {
              backgroundColor: "#455FE8",
  },
};

            export default App;
